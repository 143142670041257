<template>
  <div class="container">
    <BaseBread>
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>产品中心</BaseBreadItem>
    </BaseBread>
    <BaseOneImage v-if="dataSource" :dataSource="dataSource"/>
    <div class="product-list">
      <!-- 左：侧边栏组件 -->
      <ProductLeftMenu :menuList="menuList"/>
      <!-- 右：内容 -->
      <div class="article">
        <!-- 三级路由的出口 -->
        <div>
          <div class="order-list">
            <div v-if="loading" class="loading"></div>
            <div class="none" v-if="!loading && productList.length === 0">

              <div class="cart-none">
                <img src="@/assets/images/none.png" alt="加载失败"/>
                <p>暂无数据</p>

              </div>

            </div>
            <ProductList :products="productList"/>
          </div>
          <!-- 分页组件 -->
          <BasePagination
            v-if="total>0"
            :current-page="reqParams.pageIndex"
            :page-size="reqParams.pageSize"
            :total="total"
            @current-change="changePagerFn"
          />
        </div>
        <!--        <div v-else>-->
        <!--          <RouterView/>-->
        <!--        </div>-->

      </div>
    </div>
  </div>
</template>
<script>
import {useRoute} from "vue-router";
import {reactive, ref, watch} from "vue";
import ProductList from './components/product-list'
import {findProductByCategoryId, findProductCategory} from '@/api/product'
import BaseOneImage from "@/components/library/base-one-image.vue";
import {findAd} from "@/api/home";
import BasePagination from "@/components/library/base-pagination.vue";
import BaseBread from "@/components/library/base-bread.vue";
import BaseBreadItem from "@/components/library/base-bread-item.vue";
import ProductLeftMenu from "@/views/product/components/product-left-menu.vue";

export default {
  name: 'ProductName',
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ProductLeftMenu,
    BaseBreadItem,
    BaseBread,
    BasePagination,
    BaseOneImage,
    ProductList
  },
  setup(props) {
    const dataSource = ref([])
    findAd("productTopImg").then(data => {
      dataSource.value = data.data.list[0]
    })

// 获取当前路由对象
    const route = useRoute();
// 获取动态路由参数的name
    const id = ref()

    const loading = ref(false)
    const total = ref(0)
    const productList = ref([]);
// 筛选条件
    const reqParams = reactive({
      pageIndex: 1,
      pageSize: 6,
    })

    const menuList = ref([]);
    findProductCategory().then(data => {
      menuList.value = data.data.list
    })


    watch(() => route.params.id, (productVal) => {
      reqParams.categoryId = route.params.id
      reqParams.pageIndex = 1
      findProductByCategoryId(reqParams).then(data => {
        loading.value = true
        productList.value = data.data.records
        total.value = data.data.total
        loading.value = false
      })
    }, {immediate: true})

    // 实现分页切换
    const changePagerFn = (newPage) => {
      reqParams.pageIndex = newPage
      findProductByCategoryId(reqParams).then(data => {
        loading.value = true
        productList.value = data.data.records
        total.value = data.data.total
        loading.value = false
      })
    }
    return {menuList, productList, id, total, reqParams, loading, changePagerFn, dataSource}
  }
}

</script>

<style scoped lang="less">
.product-list {
  display: flex;

  .article {
    width: 100%;
  }
}

.cart-none {
  text-align: center;
  padding: 150px 0;
  background: #fff;

  img {
    width: 180px;
  }

  p {
    color: #999999;
    padding: 20px 0;
  }
}
</style>
