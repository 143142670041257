<template>
  <div class="product-info">
    <div class="product-list">
      <div
        class="product-item"
        v-for="item in products"
        :key="item.productId"
      >
        <div class="body">
          <div class="column products">
            <ul>
              <RouterLink :to="`/product/detail/${item.productId}`" class='product-it'>
                <li>
                  <a class="image" href="javascript:">
                    <RouterLink :to="`/product/detail/${item.productId}`">
                      <img :src="item.productImage" :alt="item.productName"/>
                    </RouterLink>
                  </a>
                  <div class="info">
                    <p class="name ellipsis-2">
                      <RouterLink :to="`/product/detail/${item.productId}`">
                        {{ item.productName }}
                      </RouterLink>
                    </p>
                    <p class="category ellipsis-2">
                      分类：{{ item.categoryName }}
                    </p>
                    <p class="attr ellipsis-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ item.productDesc }}
                    </p>
                    <p class="attr-button ellipsis-2">查看详情</p>
                  </div>
                </li>
              </RouterLink>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  name: 'ProductList',
  props: {
    products: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['on-cancel', 'on-delete', 'on-confirm', 'on-logistics'],
  setup(props) {
  }
}
</script>

<style scoped lang="less">
.product-info {
  min-height: 250px;
  background: #fff;
  display: flex;
}

.product-list {
  width: 100%;
  background-color: #fff;
}

.product-item {
  width: 50%;
  margin-bottom: 20px;
  border: 3px solid #f5f5f5;
  height: 210px;
  float: left;

  .body {
    display: flex;
    align-items: stretch;

    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;

      &.products {
        flex: 1;
        padding: 0;
        align-self: center;

        ul {
          li {
            border-bottom: 1px solid #f5f5f5;
            padding: 20px;
            display: flex;

            &:last-child {
              border-bottom: none;
            }

            .image {
              width: 210px;
              height: 120px;
              border: 1px solid #f5f5f5;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .info {
              width: 100%;
              height: 170px;
              text-align: left;
              padding: 0 20px;
              line-height: 24px;

              p {
                margin-bottom: 5px;

                &.name {
                  text-align: center;
                  height: 30px;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 25px;
                }

                &.category {
                  color: #c5a228;
                  font-size: 14px;
                  text-align: right;
                }

                &.attr {
                  color: #999;
                  height: 70px;
                  font-size: 14px;

                  span {
                    margin-right: 5px;
                  }
                }

                &.attr-button {
                  color: #999;
                  font-size: 12px;
                  text-align: right;

                  span {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
