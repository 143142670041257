<template>
  <!-- <el-scrollbar class="a"> -->
  <el-menu :default-active="defaultActive" router class="container">
    <el-menu-item :index="'/product/' + 0">
      <!-- <el-icon><i-ep-menu /></el-icon> -->
      <template #title>全部</template>
    </el-menu-item>
    <ProductLeftSubMenu :menu="menu" v-for="menu in menuList" :key="menu.categoryId"></ProductLeftSubMenu>
  </el-menu>
  <!-- </el-scrollbar> -->
</template>

<script>
import {computed} from "vue";
import {useRoute} from "vue-router";
import {ref, onMounted, onUnmounted, defineProps} from "vue";
import ProductLeftSubMenu from "./product-left-sub-menu.vue";

export default {
  name: 'ProductLeftMenu',
  components: {ProductLeftSubMenu},
  props: {
    menuList: {
      type: Object,
      default: () => ({}),
    }
  },
  setup(props) {
    const defaultActive = computed(
      () => useRoute().meta?.parentMenu || useRoute().categoryId
    );
    return {defaultActive}
    // setup(props) {
    //   const defaultActive = computed(
    //     () => useRoute().meta?.parentMenu || useRoute().categoryId
    //   );
    //   return {defaultActive}
    //
    // }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 220px;
  margin-right: 20px;
  height: 100%;

  .el-header {
  @include flex(space-between);
    border-bottom: solid 1px var(--el-menu-border-color);

    .float-left {
    @include flex(flex-start);
    }

    .float-right {
    @include flex(flex-end);
    }
  }

  .el-aside {
    width: auto;
  }
}

// .a {
//   width: 220px;
//   padding-right: 20px;
// }
</style>
