<template>
  <div class="box">
    <div class="base-oneimage">
      <img v-lazy="dataSource.filePath" :alt="dataSource.name"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseOneImage',
  props: {
    dataSource: {
      type: Object,
      default: ''
    }
  },
}
</script>

<style scoped lang="less">
.base-oneimage {
  width: 100%;
  height: 100%;

  img {
    min-height: 200px;
    height: auto;
    max-height: 300px;
    width: 100%
  }
}
</style>
